import {
    BillingStatus,
    BillingTransportType,
    CollectAccountType,
    ConsolidationType,
    Country,
    DimensionUnit,
    EquipmentType,
    InvoiceEdiSentStatus,
    InvoiceEmailSendingStatus,
    InvoiceStatus,
    InvoiceType,
    OrderStatus,
    PaymentTypeOption,
    RequestType,
    RfqStatus,
    ShipmentIdentifierType,
    TariffType,
    TaxType,
    TrackingFilterStatus,
    TrackingUpdateStatus,
    TransportType,
    type UserRole,
    UserStatus,
    Onboarding as OnboardingStatus,
} from "@lazr/openapi-client";
import { MarketPlaceStep as NewMarketPlaceStep } from "../../ui-new/definitions/MarketPlace";
import { MarketPlaceStep } from "../definitions/MarketPlace";
import {
    BatteryMaterial,
    BatteryPacking,
    HazmatAccessibiltyType,
    HazmatClass,
    HazmatMode,
    PickupStatus,
    ShipmentDocumentType,
    ServiceLevelType,
    AccessorialTypeFilter,
} from '@lazr/enums'

export const INSTRUCTIONS_CHARACTER_LIMIT = 250;

export const TEAL_BORDER_DARKEN_AMOUNT = 0.04;
export const TEAL_TEXT_ON_WHITE_DARKEN_AMOUNT = 0.1;

export const BUTTON_HOVER_DARKEN_AMOUNT = 0.04;
export const HEADER_HOVER_LIGHTEN_AMOUNT = 0.06;

export const BUTTON_HOVER_TEAL_DARKEN_AMOUNT = 0.05;
export const BUTTON_HOVER_NAVYBLUE_LIGHTEN_AMOUNT = 0.1;
export const BUTTON_HOVER_WHITE_DARKEN_AMOUNT = 0.15;

export const CHIP_DEFAULT_DARKEN_AMOUNT = 0.12;

export const BUTTON_HOVER_TRANSPARENTIZE_AMOUNT = 0.96;

export const BG_SUBTLE_GRAY_DARKEN_AMOUNT = 0.02;
export const BG_LIGHTEST_GRAY_DARKEN_AMOUNT = 0.035; // #F6F6F6
export const BG_LIGHTER_GRAY_DARKEN_AMOUNT = 0.04; // #F5F5F5
export const BG_LIGHT_GRAY_DARKEN_AMOUNT = 0.0416; // #F4F4F4
export const BG_GRAY_DARK_AMOUNT = 0.1198; // #E0E0E0
export const BG_GRAY_DARKEN_AMOUNT = 0.1529; // #D8D8D8
export const BG_DARK_GRAY_DARKEN_AMOUNT = 0.0739; // #ECECEC
export const BG_DARKER_GRAY_DARKEN_AMOUNT = 0.2138; // #C8C8C8
export const BG_DARKEST_GRAY_DARKEN_AMOUNT = 0.2274; // #C5C5C5

export const BG_DARK_BLUE_LIGHTEN_AMOUNT = 0.415; // #DAE1E5

export const BG_DARK_GREEN_LIGHTEN_AMOUNT = 0.4;

export const BG_RED_LIGHTEN_AMOUNT = 0.12;

export const BG_DARK_RED_AMOUNT = 0.02;
export const BG_DARK_GREEN_AMOUNT = 0.02;

export const COLOR_LIGHTEST_GRAY_DARKEN_AMOUNT = 0.035; // #F6F6F6
export const COLOR_LIGHTER_GRAY_DARKEN_AMOUNT = 0.04; // #F5F5F5
export const COLOR_GRAY_DARKEN_AMOUNT = 0.1529; // #D8D8D8
export const COLOR_DARK_GRAY_DARKEN_AMOUNT = 0.0739; // #ECECEC
export const COLOR_DARKER_GRAY_DARKEN_AMOUNT = 0.2138; // #C8C8C8
export const COLOR_DARKEST_GRAY_DARKEN_AMOUNT = 0.2274; // #C5C5C5

export const COLOR_LIGHTER_AMOUNT = 0.25;

export const DISABLED_OPACITY = 0.38;

export const HANDLING_UNIT_QUANTITY = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
export const HANDLING_UNIT_NMFC_SUB_CODE = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"];

export const HANDLING_UNIT_CLASS_VALUES = [
    "50",
    "55",
    "60",
    "65",
    "70",
    "77.5",
    "85",
    "92.5",
    "100",
    "110",
    "125",
    "150",
    "175",
    "200",
    "250",
    "300",
    "400",
    "500",
] as const;

export const DOCK_DEL_ACCESSORIAL = "DOCKDEL";
export const DOCK_PU_ACCESSORIAL = "DOCKPU";
export const NON_REMOVABLE_ACCESSORIALS = ["RESPU", "RESDEL"];

export const NO_CUSTOMS_BROKER_SERVICE_LEVEL = ["INTERNATIONAL_FIRST"];

class Dimensions {
    length: number | null;
    width: number | null;
    height: number | null;
    unit: DimensionUnit | null;
}

export const ENVELOPE_TYPES: Record<string, Dimensions> = {
    "Legal format": { length: 1, width: 10, height: 15, unit: DimensionUnit.IN },
    Letter: { length: 1, width: 4.13, height: 9.5, unit: DimensionUnit.IN },
    "Custom package size": { length: null, width: null, height: null, unit: null },
};

/* GD TEMPORARY TESTS */
export const ENVELOPE_TYPES2: Record<string, Dimensions> = {
    Legal: { length: 1, width: 10, height: 15, unit: DimensionUnit.IN },
    Letter: { length: 1, width: 4.13, height: 9.5, unit: DimensionUnit.IN },
    Custom: { length: null, width: null, height: null, unit: null },
};

export const HANDLING_EQUIPMENT_TYPE: Record<EquipmentType, string> = {
    [EquipmentType.DRYBOX]: "Drybox",
    [EquipmentType.REEFER]: "Reefer",
    [EquipmentType.FLATBED]: "Flatbed",
    [EquipmentType.FLATBED_ROLL_TITE]: "Flatbed Roll Tite",
    [EquipmentType.SPECIALIZED]: "Specialized",
    [EquipmentType.OTHER]: "Other",
};

export const HANDLING_TRANSPORT_TYPE: Record<TransportType, string> = {
    [TransportType.FTL]: "FTL",
    [TransportType.LTL]: "LTL",
    [TransportType.OTHER]: "Other",
    [TransportType.PARCEL]: "Parcel",
    [TransportType.ENVELOPE]: "Envelope",
    [TransportType.PAK]: "Pak",
};

export const HANDLING_REQUEST_TYPE: Record<RequestType, string> = {
    [RequestType.CUSTOM_QUOTE]: "Custom quote",
    [RequestType.WITH_COVERAGE]: "With coverage",
    [RequestType.EDI]: "EDI",
};

export const HANDLING_ACCESSORIAL_TYPE: Record<AccessorialTypeFilter, string> = {
    [AccessorialTypeFilter.IS_CALL_TAG]: AccessorialTypeFilter.IS_CALL_TAG,
};

export const HANDLING_INVOICE_TYPE: Record<InvoiceType, string> = {
    [InvoiceType.RECEIVABLE]: "Receivable",
    [InvoiceType.PAYABLE]: "Payable",
    [InvoiceType.EXTERNAL]: "External",
};
export const HANDLING_CONSOLIDATION_TYPE: Record<ConsolidationType, string> = {
    [ConsolidationType.RECEIVABLE]: "Receivable",
    [ConsolidationType.PAYABLE]: "Payable",
};

export const TRACKING_UPDATE_STATUS: Record<TrackingUpdateStatus, string> = {
    [TrackingUpdateStatus.AT_STOP]: "At stop",
    [TrackingUpdateStatus.CARRIER_DISPATCHED]: "Carrier dispatched",
    [TrackingUpdateStatus.SCHEDULED_FOR_PICKUP]: "Scheduled",
    [TrackingUpdateStatus.PICKUP_ARRIVAL]: "Pickup arrival",
    [TrackingUpdateStatus.PICKUP_DEPARTURE]: "Pickup departure",
    [TrackingUpdateStatus.INFO]: "Info",
    [TrackingUpdateStatus.IN_TRANSIT]: "In transit",
    [TrackingUpdateStatus.DELAYED_CUSTOMS_HOLD]: "Delayed customs hold",
    [TrackingUpdateStatus.DELAYED_HOLD]: "Delayed hold",
    [TrackingUpdateStatus.DELAYED_OTHER]: "Delayed other",
    [TrackingUpdateStatus.OUT_FOR_DELIVERY]: "Out for delivery",
    [TrackingUpdateStatus.DELIVERY_ARRIVAL]: "Delivery arrival",
    [TrackingUpdateStatus.DELIVERED]: "Delivered",
    [TrackingUpdateStatus.NOT_DELIVERED]: "Not delivered",
    [TrackingUpdateStatus.CUSTOMER_PICKUP]: "Customer pickup",
    [TrackingUpdateStatus.RETURN_TO_SENDER]: "Return to sender",
};

export const SHIPMENT_IDENTIFIER_TYPE: Record<ShipmentIdentifierType, string> = {
    [ShipmentIdentifierType.BILL_OF_LADING_NUMBER]: "BOL #",
    [ShipmentIdentifierType.CUSTOMER_REFERENCE]: "Customer Reference #",
    [ShipmentIdentifierType.REFERENCE]: "Reference #",
    [ShipmentIdentifierType.EXTERNAL]: "External #",
    [ShipmentIdentifierType.OTHER]: "Other",
    [ShipmentIdentifierType.PRO_BILL_NUMBER]: "Pro bill #",
    [ShipmentIdentifierType.PRO_NUMBER]: "Pro #",
    [ShipmentIdentifierType.PURCHASE_ORDER_NUMBER]: "Carrier PO #",
    [ShipmentIdentifierType.PICKUP_NUMBER]: "Pickup #",
    [ShipmentIdentifierType.TRACKING_NUMBER]: "Tracking #",
    [ShipmentIdentifierType.PIN_NUMBER]: "PIN #",
    [ShipmentIdentifierType.LOCATION_NUMBER]: "Location #",
    [ShipmentIdentifierType.MANIFEST_NUMBER]: "Manifest #",
    [ShipmentIdentifierType.CONSIGNOR_REFERENCE]: "Consignor reference #",
    [ShipmentIdentifierType.FREIGHT_FORWARDER_REFERENCE]: "Freight forwarder reference #",
    [ShipmentIdentifierType.FREIGHT_BILL_NUMBER]: "Freight bill #",
    [ShipmentIdentifierType.INBOUND_CENTER_REFERENCE]: "Inbound center reference #",
    [ShipmentIdentifierType.LOAD_LIST_REFERENCE]: "Load list reference #",
    [ShipmentIdentifierType.OUTBOUND_CENTER_REFERENCE]: "Outbound center reference #",
    [ShipmentIdentifierType.LOCAL_PAYOR_ACCOUNT_NUMBER]: "Local payor account #",
    [ShipmentIdentifierType.LOCAL_SHIPPER_ACCOUNT_NUMBER]: "Local shipper account #",
    [ShipmentIdentifierType.LOCAL_RECEIVER_ACCOUNT_NUMBER]: "Local receiver account #",
    [ShipmentIdentifierType.CUSTOMS_DECLARATION_NUMBER]: "Customs declaration #",
    [ShipmentIdentifierType.SHIPMENT_ID]: "Shipment id #",
};

export const RFQ_STATUS_MAPPING: Record<RfqStatus, string> = {
    [RfqStatus.PRELOAD]: 'Preload',
    [RfqStatus.GHOST]: 'History',
    [RfqStatus.DRAFT]: 'Draft',
    [RfqStatus.SUBMITTED]: 'Submitted',
    [RfqStatus.REVISED]: 'Revised',
    [RfqStatus.ACCEPTED]: 'Accepted',
    [RfqStatus.REJECTED]: 'Rejected',
    [RfqStatus.DECLINED]: 'Declined',
    [RfqStatus.CANCELLED]: 'Cancelled',
    [RfqStatus.ABORTED]: 'Aborted',
    [RfqStatus.EXPIRED]: 'Expired',
    [RfqStatus.BOOKED]: 'Booked',
    [RfqStatus.CARRIER_DISPATCHED]: 'Dispatched',
    [RfqStatus.AWAITING_ORGANIZATION_APPROVAL]: 'Awaiting approval',
    [RfqStatus.PENDING_CANCELLATION]: 'Pending cancellation',
};
export const INVOICE_STATUS_MAPPING: Record<InvoiceStatus, string> = {
    [InvoiceStatus.PENDING]: "Awaiting Payment",
    [InvoiceStatus.PARTIALLY_PAID]: "Partially Paid",
    [InvoiceStatus.PAID]: "Paid",
    [InvoiceStatus.REVISION_REQUESTED]: "Revision Requested",
    [InvoiceStatus.DELETED]: "Deleted",
    [InvoiceStatus.VOIDED]: "Voided",
};

export const TARIFF_TYPE_STATUS_MAPPING: Record<TariffType, string> = {
    [TariffType.CLIENT]: "Client",
    [TariffType["3PL"]]: "3PL",
    [TariffType.LAZR]: "Lazr",
    [TariffType.CLIENT_TARIFF_NO_TRANSPORT_COST]: "Client tariff no transport cost",
    [TariffType.COLLECT]: "Collect",
};

export const ORDER_STATUS_MAPPING: Record<OrderStatus, string> = {
    [OrderStatus.PRELOAD]: "Preload",
    [OrderStatus.GHOST]: "History",
    [OrderStatus.DRAFT]: "Draft",
    [OrderStatus.NEGOTIATION]: "Negotiation",
    [OrderStatus.RESERVATION]: "Reservation",
    [OrderStatus.TRANSPORT]: "In Transit",
    [OrderStatus.BILLING]: "Billing",
    [OrderStatus.COMPLETED]: "Completed",
};

export const ORDER_STATUS_STEPS: Record<OrderStatus, number> = {
    [OrderStatus.PRELOAD]: 0,
    [OrderStatus.GHOST]: 0,
    [OrderStatus.DRAFT]: 0,
    [OrderStatus.NEGOTIATION]: 0,
    [OrderStatus.RESERVATION]: 1,
    [OrderStatus.TRANSPORT]: 2,
    [OrderStatus.BILLING]: 3,
    [OrderStatus.COMPLETED]: 4,
};

export enum LazrCategories {
    COMMON_CARRIER = "COMMON_CARRIER",
    CONTRACT_CARRIER = "CONTRACT_CARRIER",
    PRIVATE_FLEET_CARRIER = "PRIVATE_FLEET_CARRIER",
    INTERMODAL = "INTERMODAL",
    OTHER = "OTHER",
}

export const LAZR_CATEGORIES_MAPPING: Record<LazrCategories, string> = {
    [LazrCategories.COMMON_CARRIER]: "Common Carrier",
    [LazrCategories.CONTRACT_CARRIER]: "Contract Carrier",
    [LazrCategories.PRIVATE_FLEET_CARRIER]: "Private Fleet Carrier",
    [LazrCategories.INTERMODAL]: "Intermodal",
    [LazrCategories.OTHER]: "Other",
};

export const MARKETPLACE_ROUTES: Record<MarketPlaceStep, string> = {
    [MarketPlaceStep.SHIPMENT_INFORMATION]: "/marketplace/shipment-info",
    [MarketPlaceStep.MARKETPLACE]: "/marketplace/quotes",
    [MarketPlaceStep.COMPLETE_ORDER]: "/marketplace/complete-order",
    [MarketPlaceStep.CONFIRMATION]: "/marketplace/confirmation",
    [MarketPlaceStep.COMPLETED]: "/orders",
};

export const NEW_MARKETPLACE_ROUTES: Record<NewMarketPlaceStep, string> = {
    [NewMarketPlaceStep.START]: "/new/marketplace/load-and-terms",
    [NewMarketPlaceStep.LOAD_AND_TERMS]: "/new/marketplace/load-and-terms",
    [NewMarketPlaceStep.ORIGIN_AND_DESTINATION]: "/new/marketplace/origin-and-destination",
    [NewMarketPlaceStep.COVERAGE]: "/new/marketplace/coverage",
    [NewMarketPlaceStep.RESULTS]: "/new/marketplace/results",
    [NewMarketPlaceStep.ADDITIONAL_DETAILS]: "/new/marketplace/additional-details",
    [NewMarketPlaceStep.CHECKOUT]: "/new/marketplace/checkout",
    [NewMarketPlaceStep.THANK_YOU]: "/new/marketplace/thank-you",
};

export const ALLOWED_PUBLIC_MARKETPLACE_ROUTES = ["/new/marketplace", "/marketplace", "/register", "/signin"];

export interface Action {
    label: string;
    priority: Priority;
}

export enum Priority {
    HIGH = 0,
    MODERATE = 1,
    MEDIUM = 2,
    LOW = 3,
}

export const BLOCK_AND_BRACE_CODE = "BAB";

export enum USER_ROLE {
    ADMIN = "Admin",
    USER = "User",
}

export enum REJECT_REASON {
    PRICE_ERROR = "Price error",
    ORDER_MISMATCH = "Order mismatch",
    OTHER = "Other",
}
export const TABLE_EMPTY_CELL = "N/A";

export const TAX_MAPPING: Record<TaxType | string, string> = {
    [TaxType.NO_TAX]: "TAX",
    [TaxType.QST_GST]: "QST",
    [TaxType.GST]: "GST",
    [TaxType.HST_NL]: "HST",
    [TaxType.HST_NS]: "HST",
    [TaxType.HST_NB]: "HST",
    [TaxType.HST_ON]: "HST",
    [TaxType.HST_PE]: "HST",
};

export const DETAILED_TAX_MAPPING: Record<string, TaxType> = {
    NONE: TaxType.NO_TAX,
    "QST + GST": TaxType.QST_GST,
    GST: TaxType.GST,
    "HST NL": TaxType.HST_NL,
    "HST NS": TaxType.HST_NS,
    "HST NB": TaxType.HST_NB,
    "HST ON": TaxType.HST_ON,
    "HST PE": TaxType.HST_PE,
};

export enum RegisterModalAction {
    DRAFT = "DRAFT",
    SUBMIT = "SUBMIT",
    REGISTER = "REGISTER",
}

export const UNAPPROVED_ORGANIZATION_MESSAGE =
    "Before being approved for credit by the Lazr team, you are limited to one (1) order of maximum $1,000.00 CAD.";

export const BILLING_STATUS_MAPPING: Record<BillingStatus, string> = {
    [BillingStatus.PENDING_PRICE_REVIEW]: "Pending price review",
    [BillingStatus.PENDING_INVOICE]: "Pending invoice",
    [BillingStatus.AWAITING_PAYMENT]: "Awaiting payment",
    [BillingStatus.PENDING_PAYMENT]: "Pending payment",
    [BillingStatus.PAYMENT_PAST_DUE]: "Payment past due",
};

export const HANDLING_BILLING_TRANSPORT_TYPE: Record<BillingTransportType, string> = {
    [BillingTransportType.FTL]: "FTL",
    [BillingTransportType.LTL]: "LTL",
    [BillingTransportType.OTHER]: "Other",
    [BillingTransportType.MAILING]: "Parcel, Pak, Envelope",
};

export const INVOICE_EMAIL_SENT_STATUS_MAPPING: Record<InvoiceEmailSendingStatus, string> = {
    [InvoiceEmailSendingStatus.SENT]: "Sent",
    [InvoiceEmailSendingStatus.NOT_SENT]: "Not Sent",
    [InvoiceEmailSendingStatus.SENT_FAILED]: "Sent Failed",
};

export const USER_STATUS_MAPPING: Record<UserStatus, string> = {
    [UserStatus.ACTIVE]: "Active",
    [UserStatus.VERIFIED]: "Verified",
    [UserStatus.INACTIVE]: "Inactive",
    [UserStatus.UNVERIFIED]: "Unverified",
};

export enum AddressBookType {
    ORIGIN = "ORIGIN",
    DESTINATION = "DESTINATION",
    CUSTOM_BROKER = "CUSTOM_BROKER",
    DUTY_BILL = "DUTY_BILL",
}

export const ORGANIZATION_ROLE_MAPPING: Record<UserRole, string> = {
    ADMIN: "Admin",
    AGENT: "Agent",
    SHIPPER: "Shipper",
    ACCOUNTANT: "Accountant",
    SUPPLIER: "Supplier",
};

export const ORGANIZATION_PERMISSION_KEY_MAPPING: Record<string, string> = {
    performSearch: "Perform search",
    viewCompanyTariffQuotes: "View company tariff quotes",
    viewCompanyTariffQuotesPrice: "View company tariff quotes price",
    allowCollectPaymentTerms: "Allow collect payment terms",
    allowPrepaidPaymentTerms: "Allow prepaid payment terms",
    submitOrder: "Submit order",
    viewAllCompanyOrders: "View all company orders",
    viewCompanyInvoices: "View company invoices",
    viewCompanyAddressBook: "View company address book",
};

export const ADDRESS_ACCOUNT_TYPE_MAPPING: Record<CollectAccountType, string> = {
    [CollectAccountType.FREIGHT]: "Freight",
    [CollectAccountType.MAILING]: "Mailing",
};

export const PAYMENT_TYPE_OPTION_MAPPING: Record<PaymentTypeOption, string> = {
    [PaymentTypeOption.COLLECT]: "Collect",
    [PaymentTypeOption.PREPAID]: "Prepaid",
};

export const QUOTE_RESULT_SORT_TYPE_MAPPING: Record<string, string> = {
    LOWEST_PRICE: "Lowest Price",
    LAZR_CHOICE: "Lazr's Choice",
    BEST_VALUE: "Best Value",
    FASTEST: "Fastest",
    ECO_FRIENDLY: "Eco-friendly",
};
export const STATE_REQUIRING_COUNTRIES: string[] = [Country.US, Country.CA];

export const INVOICE_EDI_SENT_STATUS_MAPPING: Record<InvoiceEdiSentStatus, string> = {
    [InvoiceEdiSentStatus.SUCCESS]: "Success",
    [InvoiceEdiSentStatus.FAILURE]: "Failure",
};

export const ONBOARDING_FILTER_STATUS_MAPPING: Record<OnboardingStatus, string> = {
    [OnboardingStatus.API]: "API",
    [OnboardingStatus.CANADA_CHAMBER_OF_COMMERCE]: "Canada Chamber of Commerce",
    [OnboardingStatus.GMX]: "GMX",
    [OnboardingStatus.GMX_IMPORT]: "GMX Import",
    [OnboardingStatus.GOOGLE_ADS]: "Google Ads",
    [OnboardingStatus.TRACKING_PAGE]: "Tracking Page",
    [OnboardingStatus.WEBSITE]: "Website",
};

export const TRACKING_FILTER_STATUS_MAPPING: Record<TrackingFilterStatus, string> = {
    [TrackingFilterStatus.DISPATCHED]: "Dispatched",
    [TrackingFilterStatus.PICKUP]: "Pickup",
    [TrackingFilterStatus.IN_TRANSIT]: "In Transit",
    [TrackingFilterStatus.DELAYED]: "Delayed",
    [TrackingFilterStatus.DELIVERY]: "Delivery",
    [TrackingFilterStatus.EXCEPTION]: "Exception",
};

export const SHIPMENT_DOCUMENT_TYPE_MAPPING: Record<ShipmentDocumentType, string> = {
    [ShipmentDocumentType.CORRESPONDENCE_NO_CUSTOMS_VALUE]: "Correspondence/No customs value",
    [ShipmentDocumentType.ACCOUNTING_DOCUMENTS]: "Accounting documents",
    [ShipmentDocumentType.ANALYSIS_REPORTS]: "Analysis reports",
    [ShipmentDocumentType.APPLICATIONS]: "Applications (completed)",
    [ShipmentDocumentType.BANK_STATEMENTS]: "Bank statements",
    [ShipmentDocumentType.BID_QUOTATIONS]: "Bid quotations",
    [ShipmentDocumentType.BILLS_OF_SALE]: "Bills of sale",
    [ShipmentDocumentType.BIRTH_CERTIFICATES]: "Birth certificates",
    [ShipmentDocumentType.BONDS]: "Bonds",
    [ShipmentDocumentType.CHECKS]: "Checks (completed)",
    [ShipmentDocumentType.CLAIM_FILES]: "Claim files",
    [ShipmentDocumentType.CLOSING_STATEMENTS]: "Closing statements",
    [ShipmentDocumentType.CONFERENCE_REPORTS]: "Conference reports",
    [ShipmentDocumentType.CONTRACTS]: "Contracts",
    [ShipmentDocumentType.COST_ESTIMATES]: "Cost estimates",
    [ShipmentDocumentType.COURT_TRANSCRIPTS]: "Court transcripts",
    [ShipmentDocumentType.CREDIT_APPLICATIONS]: "Credit applications",
    [ShipmentDocumentType.DATA_SHEETS]: "Data sheets",
    [ShipmentDocumentType.DEEDS]: "Deeds",
    [ShipmentDocumentType.EMPLOYEMENT_PAPERS]: "Employement papers",
    [ShipmentDocumentType.ESCROW_INSTRUCTIONS]: "Escrow instructions",
    [ShipmentDocumentType.EXPORT_PAPERS]: "Export Papers",
    [ShipmentDocumentType.FINANCIAL_STATEMENT]: "Financial statement",
    [ShipmentDocumentType.IMMIGRATION_PAPERS]: "Immigration papers",
    [ShipmentDocumentType.INCOME_STATEMENTS]: "Income statements",
    [ShipmentDocumentType.INSURANCE_DOCUMENTS]: "Insurance documents",
    [ShipmentDocumentType.INTEROFFICE_MEMOS]: "Interoffice memos",
    [ShipmentDocumentType.INVENTORY_REPORTS]: "Inventory reports",
    [ShipmentDocumentType.INVOICES]: "Invoices (completed)",
    [ShipmentDocumentType.LEASES]: "Leases",
    [ShipmentDocumentType.LEGAL_DOCUMENTS]: "Legal documents",
    [ShipmentDocumentType.LETTER_OF_CREDIT_PACKETS]: "Letter of credit packets",
    [ShipmentDocumentType.LETTERS_AND_CARDS]: "Letters and cards",
    [ShipmentDocumentType.LOAN_DOCUMENTS]: "Loan documents",
    [ShipmentDocumentType.MARRIAGE_CERTIFICATES]: "Marriage certificates",
    [ShipmentDocumentType.MEDICAL_RECORDS]: "Medical records",
    [ShipmentDocumentType.OFFICE_RECORDS]: "Office records",
    [ShipmentDocumentType.OPERATING_AGREEMENTS]: "Operating agreements",
    [ShipmentDocumentType.PARENT_APPLICATIONS]: "Patent applications",
    [ShipmentDocumentType.PERMITS]: "Permits",
    [ShipmentDocumentType.PHOTOCOPIES]: "Photocopies",
    [ShipmentDocumentType.PROPOSALS]: "Proposals",
    [ShipmentDocumentType.PROSPECTUSES]: "Prospectuses",
    [ShipmentDocumentType.PURCHASE_ORDERS]: "Purchase orders",
    [ShipmentDocumentType.QUOTATIONS]: "Quotations",
    [ShipmentDocumentType.RESERVATION_CONFIRMATION]: "Reservation confirmation",
    [ShipmentDocumentType.RESUMES]: "Resumes",
    [ShipmentDocumentType.SALES_AGREEMENTS]: "Sales agreements",
    [ShipmentDocumentType.SALES_REPORTS]: "Sales reports",
    [ShipmentDocumentType.SHIPPING_DOCUMENTS]: "Shipping documents",
    [ShipmentDocumentType.STATEMENTS_REPORTS]: "Statements/Reports",
    [ShipmentDocumentType.STATISTICAL_DATA]: "Statistical data",
    [ShipmentDocumentType.STOCK_INFORMATION]: "Stock information",
    [ShipmentDocumentType.TAX_PAPERS]: "Tax Papers",
    [ShipmentDocumentType.TRADE_CONFIRMATION]: "Trade confirmation",
    [ShipmentDocumentType.TRANSCRIPTS]: "Transcripts",
    [ShipmentDocumentType.WARRANTY_DEEDS]: "Warranty deeds",
    [ShipmentDocumentType.OTHER]: "Other",
};

export const HAZMAT_ACCESSIBILTY_TYPE_MAPPING: Record<HazmatAccessibiltyType, string> = {
    [HazmatAccessibiltyType.ACCESSIBLE]: "Accessible",
    [HazmatAccessibiltyType.INACCESSIBLE]: "Inaccessible",
};
export const HAZMAT_CLASS_MAPPING: Record<HazmatClass, string> = {
    [HazmatClass.FULLYREGULATED]: "Fully Regulated",
    [HazmatClass.UN3373]: "UN3373",
    [HazmatClass.UN1845]: "UN1845",
    [HazmatClass.LESSTHAN500KGEXEMPT]: "Less Than 500kg Exempt",
    [HazmatClass.LIMITEDQUANTITIES]: "Limited Quantities",
};

export const HAZMAT_MODE_MAPPING: Record<HazmatMode, string> = {
    [HazmatMode.AIR]: "Air",
    [HazmatMode.GROUND]: "Ground",
};

export const BATTERY_MATERIAL_MAPPING: Record<BatteryMaterial, string> = {
    [BatteryMaterial.LITHIUM_METAL]: "Lithium Metal",
    [BatteryMaterial.LITHIUM_ION]: "Lithium Ion",
};
export const BATTERY_PACKING_MAPPING: Record<BatteryPacking, string> = {
    //UPS ONLY
    [BatteryPacking.ALONE_IN_BOX]: "Alone In Box",
    [BatteryPacking.PACKED_WITH_EQUIPMENT]: "Packed With Equipment",
    [BatteryPacking.CONTAINED_IN_EQUIPMENT]: "Contained In Equipment",
};

export const PICKUP_STATUS_MAPPING: Record<PickupStatus, string> = {
    [PickupStatus.SCHEDULED]: "Scheduled",
    [PickupStatus.DISPATCHED]: "Dispatched",
    [PickupStatus.PICKED_UP]: "Picked up",
    [PickupStatus.CLOSED]: "Closed",
    [PickupStatus.FAILED]: "Failed",
    [PickupStatus.CANCELLED]: "Cancelled",
};

export const SERVICE_LEVEL_TYPE_MAPPING: Record<ServiceLevelType, string> = {
    [ServiceLevelType.EXPRESS]: "Express",
    [ServiceLevelType.DEFERRED]: "Deferred",
    [ServiceLevelType.GROUND]: "Ground",
    [ServiceLevelType.MAIL]: "Mail",
    [ServiceLevelType.UNKNOWN]: "Others",
};
